/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    code: "code",
    h3: "h3",
    pre: "pre"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "The Syntax Module enhances the Code Block format by automatically detecting and applying syntax highlighting. The excellent ", React.createElement(_components.a, {
    href: "https://highlightjs.org/"
  }, "highlight.js"), " library is used as a dependency to parse and tokenize code blocks."), "\n", React.createElement(_components.p, null, "In general, you may ", React.createElement(_components.a, {
    href: "https://highlightjs.readthedocs.io/en/latest/api.html#configure-options"
  }, "configure"), " highlight.js as needed. However, Quill expects and requires the ", React.createElement(_components.code, null, "useBR"), " option to be ", React.createElement(_components.code, null, "false"), "."), "\n", React.createElement(_components.h3, null, "Example"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-html"
  }, "<!-- Include your favorite highlight.js stylesheet -->\n<link href=\"highlight.js/monokai-sublime.min.css\" rel=\"stylesheet\">\n\n<!-- Include the highlight.js library -->\n<script href=\"highlight.js\"></script>\n\n<script>\nhljs.configure({   // optionally configure hljs\n  languages: ['javascript', 'ruby', 'python']\n});\n\nconst quill = new Quill('#editor', {\n  modules: {\n    syntax: true,              // Include syntax module\n    toolbar: [['code-block']]  // Include button in toolbar\n  },\n  theme: 'snow'\n});\n</script>\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
